import client from "@/api/client"
import { useTranslation } from "react-i18next"
import { useMutation, useQueryClient } from "react-query"
import useAppToast from "./useAppToast"
import { MembershipRequest } from "@/typings/api"
import { useSession } from "next-auth/react"

const useJoinGroup = ({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void
  onError?: () => void
} = {}) => {
  const { t } = useTranslation()
  const toast = useAppToast()
  const { data: userData } = useSession()
  const queryClient = useQueryClient()

  return useMutation(
    "post-recommendations",
    ({ groupId }: { groupId?: number }) =>
      client.memberships.postMemberships({
        data: {
          membership_role: "user",
          group: groupId,
          users_permissions_user: userData?.userId,
        },
      } as MembershipRequest),
    {
      onSuccess: () => {
        toast({
          title: t("join_group.success"),
          status: "success",
        })
        queryClient.invalidateQueries("get-groups")
        onSuccess?.()
      },
      onError: (e: any) => {
        const message = e?.response?.data?.error?.message
        toast({
          title:
            message === "user_membership_already_exists"
              ? t("join_group.user_membership_already_exists")
              : t("global.defaultError"),
          status: "error",
        })
        onError?.()
      },
    }
  )
}

export default useJoinGroup
